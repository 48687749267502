import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: `product-catalog-web@${process.env.APP_VERSION}`,
    environment: process.env.APP_ENV,
    integrations: [new BrowserTracing()],
    attachStacktrace: true,
    tracesSampleRate: 1.0
});
